import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ROUTES, CURRENT_PAGE, PER_PAGE} from '../constants/common'
import instance from '../services/baseService';

const generateNotificationUrl = (notification) => {
    console.log(notification);
    let baseUrl = `/`; // Adjust as per your app's base route

    switch (notification.module) {
        case "GSTR1":
            baseUrl += `gstr-1/${notification.client_id}`;
            break;
        case "GSTR3B":
                baseUrl += `gstr3b/${notification.client_id}`;
                break;
        case "GSTR9":
            baseUrl += `gstr9/${notification.client_id}`;
            break;
        case "GSTR9C":
            baseUrl += `gstr9c/${notification.client_id}`;
            break;
        case "REFUND":
            baseUrl += `refunds/${notification.client_id}`;
            break;
        case "GENERAL":
            baseUrl += `clients/tax-forms/${notification.client_id}`;
            break;
        case "HSN_REPO":
            baseUrl += `hsn/sac`;    
            break;
        case "HSN_QUERY":
            baseUrl += `query-details/${notification.params.client_id}/${notification.params.req_id}`;    
            break;
        default:
            baseUrl += ''; // Fallback for other routes
    }

    return baseUrl;
};

const updateChatParams = (data, client_id, chat_type, action) => {
    const client = data.find(item => item.client_id === client_id);
    
    if (client) {
        if (action === 'add' && !client.chat_type.includes(chat_type)) {
            // Add chat_type if it doesn't already exist
            client.chat_type.push(chat_type);
        } else if (action === 'remove') {
            // Remove chat_type if it exists
            client.chat_type = client.chat_type.filter(type => type !== chat_type);
        }
    } else if (action === 'add') {
        // If client_id doesn't exist and action is 'add', create a new object
        data.push({ client_id: client_id, chat_type: [chat_type] });
    }
};

export const getNotifications = createAsyncThunk('notification/getNotifications', async ({page, latest_notification_id}, thunkAPI) => {
    try {
        const data = {
            page: CURRENT_PAGE,
            perpage: PER_PAGE
        }
        if (page ) data.page  = page;
        //if (latest_notification_id ) data.latest_id  = latest_notification_id;
        const response = await instance.post(ROUTES.LIST_NOTIFICATIONS, data);
        return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response ? error?.response?.data?.message : 'Notification Fetching failed')
    }
})

export const fetchNewNotifications = createAsyncThunk('notification/fetchNewNotifications', async ({page, latest_notification_id}, thunkAPI) => {
    try {
        const data = {
            page: CURRENT_PAGE,
            perpage: PER_PAGE
        }
        if (page ) data.page  = page;
        if (latest_notification_id ) data.latest_id  = latest_notification_id;
        const response = await instance.post(ROUTES.LIST_NOTIFICATIONS, data);
        return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response ? error?.response?.data?.message : 'Notification Fetching failed')
    }
})

export const notificationSlice = createSlice({
    name: 'notification',
    initialState: {
        isNotificationRoute: false,
        notification: null,
        notificationUrl: null,
        notificationList: [],
        loading: false,
        latestNotificationId: null,
        newNotificationFound: false,
        fcmNotification: {},
        fcmNotificationReceived: false,
        fcmChatNotificationReceived: false,
        fcmDeviceToken: null,
        fcmChatParams: [],
    },
    reducers: {
        setNotification: (state, action) => {
            state.notification = action.payload;
            state.isNotificationRoute = true;
            state.notificationUrl = generateNotificationUrl(action.payload);
        },
        clearNotification: (state) => {
            state.isNotificationRoute = false;
            state.notification = null;
            state.notificationUrl = null;
            state.newNotificationFound = false;
        },
        setFcmNotification: (state, action) => {
            state.fcmNotification = action.payload;
            state.newNotificationFound = true;
            state.fcmNotificationReceived = true;
            if(action.payload.is_chat === "1"){
                state.fcmChatNotificationReceived = true;
                updateChatParams(state.fcmChatParams, action.payload.client_id, action.payload.chat_type, 'add');
            }
        },
        clearFcmNotification: (state) => {
            state.fcmNotification = {};
            state.fcmChatNotificationReceived = false;
            state.fcmNotificationReceived = false;
        },
        setFcmDeviceToken: (state, action) => {
            state.fcmDeviceToken = action.payload;
            localStorage.setItem("preface_admin_fcm_token", action.payload)
        },
        clearFcmChat: (state, action) => {
            updateChatParams(state.fcmChatParams, action.payload?.client_id, action.payload?.chat_type, 'remove');
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getNotifications.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getNotifications.fulfilled, (state, action) => {
            //console.log(action.payload.data);
            state.loading = false;
            state.notificationList = action.payload.data?.lists;
            if (action.payload.data?.lists.length > 0) {
                state.latestNotificationId = action.payload.data?.lists[0].id;  // store the id of the 0th index
            }
        })
        builder.addCase(getNotifications.rejected, (state, action) => {
            state.loading = false;
            //console.log(action);
            state.errorMsg = action.payload?.data ? action.payload?.data : 'Failed to fetch notifications';
        })

        builder.addCase(fetchNewNotifications.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(fetchNewNotifications.fulfilled, (state, action) => {
            console.log(action.payload.data);
            state.loading = false;
            //state.notificationList = action.payload.data?.lists;
            if (action.payload.data?.lists.length > 0) {
                state.newNotificationFound = true;
            }
        })
        builder.addCase(fetchNewNotifications.rejected, (state, action) => {
            state.loading = false;
            console.log(action);
            state.errorMsg = action.payload?.data ? action.payload?.data : 'Failed to fetch notifications';
        })
    }
})

export const { setNotification,clearNotification, setFcmNotification, clearFcmNotification, setFcmDeviceToken, clearFcmChat} = notificationSlice.actions;

export default notificationSlice.reducer;