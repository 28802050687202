import React, { useState, useEffect } from "react";
import { Row, Col, Button, Table, Badge } from "react-bootstrap";
import StepProgressBar from "../common/StepProgressBar";
import ReactQuill from "react-quill";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import Base from "../Base";
import { FaExpand, FaTrashCan, FaUserTie, FaUser } from "react-icons/fa6";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import DescriptionCell from "./descriptionCell";
import "./hsn.css";
import PaginationComponent from "../common/Pagination";
import { useSelector, useDispatch } from "react-redux";
import {
  resetHsnSacState,
  getQueryDetails,
  createAdminComment,
  getCommentList,
  AdminQueryApprove,
} from "../../slices/hsnSacReducer";
import {
  modules
} from "../../constants/hsnSac";
import SheetSubmitSkipModal from "../common/SheetSubmitSkipModal";
const QueryDetailsPage = () => {
  const per_page = 25;
  const [current_page, setCurrentPage] = useState(1);
  const [quillContent, setQuillContent] = useState("");
  const [showSkipModal, setShowSkipModal] = useState(false);
  const [expandedComments, setExpandedComments] = useState({});
  const dispatch = useDispatch();
  const {
    queryDetails,
    commentList,
    commentId,
    total_count,
    loading,
    error,
    success,
    successMsg,
  } = useSelector((state) => state.hsnSac);
  const { role_id: roleId } = useSelector((state) => state.common);
  const { client_id, query_id } = useParams();

  useEffect(() => {
    dispatch(resetHsnSacState());
    const fetchData = async () => {
      try {
        await dispatch(
          getQueryDetails({ clientId: client_id, id: query_id })
        ).unwrap();
        await dispatch(
          getCommentList({
            clientId: client_id,
            id: query_id,
          })
        ).unwrap();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [dispatch, client_id, query_id]);

  const handlePagination = async (page) => {
    try {
      setCurrentPage(page);
      await dispatch(
        getCommentList({
          page: page,
          perpage: per_page,
          clientId: client_id,
          id: query_id,
        })
      ).unwrap();
    } catch (error) {
      console.error("Error fetching comments for the selected page:", error);
    }
  };

  const hsnList = Array.isArray(queryDetails?.hsn_list)
    ? queryDetails.hsn_list
    : [];

  const statusMap = {
    1: { text: "Completed", icon: <FaCheckCircle />, variant: "success" },
    2: {
      text: "Waiting for admin reply",
      icon: <FaExclamationCircle />,
      variant: "warning",
    },
    3: {
      text: "Waiting for client reply",
      icon: <FaExclamationCircle />,
      variant: "warning",
    },
    0: { text: "No Action", icon: <FaExclamationCircle />, variant: "warning" },
  };

  const badge = statusMap[queryDetails?.status];

  const isContentEmpty = (content) => {
    const strippedContent = content.replace(/<[^>]+>/g, "").trim(); // Remove HTML tags and trim
    return strippedContent === "";
  };

  const handleQuillChange = (value) => {
    setQuillContent(value);
  };

  const handleSubmit = async () => {
    try {
      await dispatch(
        createAdminComment({
          clientId: client_id,
          id: query_id,
          comment: quillContent,
        })
      ).unwrap();
      await dispatch(
        getQueryDetails({ clientId: client_id, id: query_id })
      ).unwrap();
      await dispatch(
        getCommentList({
          clientId: client_id,
          id: query_id,
        })
      ).unwrap();
      setQuillContent("");
    } catch (error) {
      console.error("Error during submission:", error);
    }
  };

  const handleApproveReject = async (approve) => {
    try {
      await dispatch(
        AdminQueryApprove({
          clientId: client_id,
          id: query_id,
          comment_id: commentId,
          approve: approve,
        })
      ).unwrap();
      await dispatch(
        getQueryDetails({ clientId: client_id, id: query_id })
      ).unwrap();
      await dispatch(
        getCommentList({
          clientId: client_id,
          id: query_id,
        })
      ).unwrap();
    } catch (error) {
      console.error("Error during submission:", error);
    }
  };

  const toggleCommentView = (index) => {
    setExpandedComments((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  const handleSkipClose = async () => {
    setShowSkipModal(false);
  };

  const handleSkipSubmit = async (option) => {
    dispatch(
      AdminQueryApprove({
        clientId: client_id,
        id: query_id,
        comment_id: commentId,
        approve: 3,
        next_level: option,
      })
    );
  };

  return (
    <Base hideDatepicker={true}>
      {loading && (
        <div className="d-flex justify-content-center align-items-center mt-4">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      {!loading && (
        <div className="p-4">
          <span
            className={"bg-[#0d4473] text-white p-2 px-3 rounded-full relative"}
          >
            <span className="font-poppins fw-bold">Query Details</span>
            <div className=" absolute w-0 h-0 left-10 -bottom-2 border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-t-[10px] border-t-[#0d4473]"></div>
          </span>

          {/* Alert Messages */}
          {(success || error) && (
            <div className="row mt-4">
              <div className="col-md-12 justify-content-center d-flex">
                <Alert
                  dismissible
                  variant={success ? "success" : "danger"}
                  onClose={() => dispatch(resetHsnSacState())}
                >
                  <p className="mb-0">{success ? successMsg : error}</p>
                </Alert>
              </div>
            </div>
          )}
          <div className="mt-4 bg-light p-4 rounded shadow-sm">
            <Row className="align-items-center">
              <Col xs={10}>
                <h4>Description:</h4>
                <div
                  dangerouslySetInnerHTML={{ __html: queryDetails?.query }}
                />
              </Col>
              <Col xs={2} className="text-end " style={{ paddingLeft: "0px" }}>
                {badge && (
                  <Button
                  variant={badge.variant}
                    style={{
                      whiteSpace: "nowrap",
                      cursor: "default",
                      pointerEvents: "none",
                    }}
                    className="px-3 py-2 d-flex align-items-center gap-2 "
                  >
                    {badge.icon} {badge.text}
                  </Button>
                )}
              </Col>
            </Row>
          </div>

          <div className="mt-4">
            <h5>HSN/SAC Details</h5>
            <Table striped>
              <thead>
                <tr>
                  <th>Index</th>
                  <th>HSN/SAC</th>
                  <th>Description</th>
                  <th>Rate</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {hsnList.length > 0 ? (
                  hsnList.flat().map((item, index) => (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{item.hsn_number}</td>
                      <td>{item.description}</td>
                      <td>{item.rate}</td>
                      <td>{item.updated_time_formatted?.split("T")[0]}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center">
                      No HSN/SAC details available.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>

          <div className="mt-4">
            {queryDetails ? (
              (queryDetails.status === 0 || queryDetails.status === 2) && (
                <>
                  {queryDetails.pending_comments ? (
                    <div className="bg-light p-3 rounded shadow-sm">
                      <StepProgressBar
                        approverLevels={queryDetails.pending_comments.levels}
                      />
                      <h5>Approval Pending:</h5>
                      <Row className="align-items-center border-bottom py-2">
                        <Col xs={1} className="d-flex justify-content-center">
                          <FaUserTie size={20} className="text-primary" />
                        </Col>
                        <Col xs={10}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: queryDetails.pending_comments.comment,
                            }}
                          />

                          <small className="text-muted">
                            {queryDetails.pending_comments
                              .created_user_profile ? (
                              <>
                                <strong>
                                  {
                                    queryDetails.pending_comments
                                      .created_user_profile.name
                                  }
                                </strong>{" "}
                                |{" "}
                                {
                                  queryDetails.pending_comments
                                    .created_user_profile.email
                                }{" "}
                                |{" "}
                              </>
                            ) : (
                              <>
                                <strong>Unknown User</strong> | <span>NA</span>{" "}
                                |{" "}
                              </>
                            )}
                            {queryDetails.pending_comments
                              .created_time_formatted
                              ? new Date(
                                  queryDetails.pending_comments.created_time_formatted
                                ).toLocaleDateString()
                              : "NA"}{" "}
                            |{" "}
                            {queryDetails.pending_comments
                              .created_time_formatted
                              ? new Date(
                                  queryDetails.pending_comments.created_time_formatted
                                ).toLocaleTimeString(undefined, {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: true,
                                })
                              : "NA"}
                          </small>
                        </Col>
                      </Row>

                      {roleId > queryDetails.pending_comments.approver_level ? (
                        <Badge bg="primary" className="m-3 p-2">
                          Waiting for approval
                        </Badge>
                      ) : (
                        <div className="mt-4 d-flex justify-content-end gap-2">
                          {roleId !== 1 && (
                            <Button
                              variant="secondary"
                              onClick={() => setShowSkipModal(true)}
                            >
                              Skip to Higher Level
                            </Button>
                          )}
                          <Button
                            variant="success"
                            onClick={() =>
                              handleApproveReject(
                                1,
                                queryDetails.pending_comments.id
                              )
                            }
                          >
                            Approve
                          </Button>
                          <Button
                            variant="danger"
                            onClick={() =>
                              handleApproveReject(
                                2,
                                queryDetails.pending_comments.id
                              )
                            }
                          >
                            Reject
                          </Button>
                        </div>
                      )}
                    </div>
                  ) : (
                    <>
                      <h5>Write a Comment on this:</h5>
                      <ReactQuill
                        theme="snow"
                        placeholder="Type your comments here..."
                        modules={modules}
                        value={quillContent}
                        onChange={handleQuillChange}
                      />
                      <div className="mt-4 d-flex justify-content-end gap-2">
                        <Button
                          variant="primary"
                          onClick={() => handleSubmit()}
                          disabled={isContentEmpty(quillContent)}
                        >
                          Submit
                        </Button>
                      </div>
                    </>
                  )}
                </>
              )
            ) : (
              <div className="text-center text-danger">
                <p>Failed to load query details. Please try again later.</p>
              </div>
            )}
          </div>

          {/* Comments Section */}
          <div className="mt-4">
            <h5>Comments</h5>
            <div className="bg-light p-3 rounded shadow-sm">
              {commentList.length > 0 ? (
                commentList.map((comment, index) => (
                  <Row
                    key={index}
                    className="align-items-center border-bottom py-2"
                  >
                    {/* Profile Icon */}
                    <Col xs={1} className="d-flex justify-content-center">
                      {comment.team === 1 ? (
                        <FaUserTie size={20} className="text-primary" />
                      ) : (
                        <FaUser size={20} className="text-success" />
                      )}
                    </Col>

                    {/* Comment Details */}
                    <Col xs={8}>
                      <strong>{comment.team === 1 ? "Admin" : "Client"}</strong>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            expandedComments[index] ||
                            comment.comment.split(" ").length <= 15
                              ? comment.comment
                              : `${comment.comment
                                  .split(" ")
                                  .slice(0, 15)
                                  .join(" ")}...`,
                        }}
                      />

                      <small className="text-muted">
                        {comment.created_user_profile ? (
                          <>
                            <strong>{comment.created_user_profile.name}</strong>{" "}
                            | {comment.created_user_profile.email} |{" "}
                          </>
                        ) : (
                          <>
                            <strong>Unknown User</strong> | <span>NA</span> |{" "}
                          </>
                        )}
                        {comment.created_time_formatted
                          ? new Date(
                              comment.created_time_formatted
                            ).toLocaleDateString()
                          : "NA"}{" "}
                        |{" "}
                        {comment.created_time_formatted
                          ? new Date(
                              comment.created_time_formatted
                            ).toLocaleTimeString(undefined, {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            })
                          : "NA"}
                      </small>
                    </Col>

                    {/* Actions */}
                    <Col
                      xs={2}
                      className=" d-flex text-end justify-content-end"
                    >
                      <a
                        className="p-0 mx-2 text-sm d-flex align-items-center cursor-pointer"
                        onClick={() => toggleCommentView(index)}
                      >
                        {expandedComments[index] ? "Show Less" : "Show More"}{" "}
                        <FaExpand className="ml-1" />
                      </a>
                    </Col>
                  </Row>
                ))
              ) : (
                <div className="text-center py-3">
                  <strong>No comments available</strong>
                </div>
              )}
              <PaginationComponent
                total_count={total_count}
                current_page={current_page}
                handleOnClick={handlePagination}
                per_page={per_page}
              />
            </div>
          </div>
        </div>
      )}
      <SheetSubmitSkipModal
        showSkipModal={showSkipModal}
        handleSkipClose={handleSkipClose}
        handleSkipSubmit={handleSkipSubmit}
      />
    </Base>
  );
};

export default QueryDetailsPage;
