import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { FaUserCircle, FaArrowRight } from "react-icons/fa";
import "./hierarchy.css";
import { getApprovalLevels,approvalLevelUpdate } from "../../slices/hsnSacReducer";
import { useSelector, useDispatch } from "react-redux";

const RoleSelectionPopup = ({ show, onHide }) => {
  const [selectedRoles, setSelectedRoles] = useState([]);
  const dispatch = useDispatch();
  const roles = [
    { id: 5, name: "Research Team" },
    { id: 2, name: "Manager" },
    { id: 1, name: "Director" },
  ];
  const {
    approval_level_list,
    total_count,
    loading,
    error,
    success,
    successMsg,
  } = useSelector((state) => state.hsnSac);

  useEffect(() => {
    if (show) {
      dispatch(getApprovalLevels());
    }
  }, [show]);

  useEffect(() => {
    if (approval_level_list?.length > 0) {
      const approvedRoleIds = approval_level_list.map((item) => item.id);
      setSelectedRoles(approvedRoleIds);
    }
  }, [approval_level_list]);

  const handleCheckboxChange = (role) => {
    if (selectedRoles.includes(role)) {
      setSelectedRoles(selectedRoles.filter((r) => r !== role));
    } else {
      setSelectedRoles([...selectedRoles, role]);
    }
  };

  const handleUpdate = () => {
    console.log("Selected Roles:", selectedRoles);
    dispatch(approvalLevelUpdate({ approver_levels: selectedRoles }));
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      backdrop="static"
      size="lg"
      dialogClassName="custom-modal-width"
    >
      <Modal.Header closeButton>
        <Modal.Title>Approval Hierarchy</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="step-container-horizontal">
          {roles.map((role, index) => (
            <div key={index} className="step-horizontal">
              <div className="step-icon">
                <FaUserCircle size={24} style={{ color: "#0d4473" }} />
              </div>
              <div className="step-label">
                <span>{role.name}</span>
                <Form.Check
                  type="checkbox"
                  checked={selectedRoles.includes(role.id)} // Dynamically control checked state
                  onChange={() => handleCheckboxChange(role.id)}
                  className="custom-checkbox"
                />
              </div>
              {index < roles.length - 1 && (
                <div className="step-arrow-horizontal">
                  <FaArrowRight size={24} style={{ color: "#0d4473" }} />
                </div>
              )}
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" disabled={selectedRoles.length === 0} onClick={handleUpdate}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RoleSelectionPopup;
