import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../services/baseService";
import { errorMsgCreator } from "../utils";
import { ROUTES } from "../constants/hsnSac";


export const createHsnSac = createAsyncThunk(
  "hsnSac/createHsnSac",
  async ({ data }, thunkAPI) => {
    try {
      const response = await instance.post(ROUTES.CREATE_HSNSAC, data);
        return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data);
    }
  }
);


export const updateHsnSac = createAsyncThunk(
  "hsnSac/updateHsnSac",
  async ({ data }, thunkAPI) => {
    try {
      const response = await instance.post(ROUTES.UPDATE_HSNSAC, data);
        return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data);
    }
  }
);


export const getHsnSac = createAsyncThunk(
    "hsnSac/getHsnSac",
    async ({ page = 1, perpage = 25,query,types } , thunkAPI) => {
      try {
        const response = await instance.post(ROUTES.LIST_HSNSAC, {
          page,
          perpage,
          query,
          types,
        });
        return response.data;
      } catch (err) {
        return thunkAPI.rejectWithValue(err?.response?.data);
      }
    }
  );

  export const HsnSacPendingList = createAsyncThunk(
    "hsnSac/HsnSacPendingList",
    async ({page = 1, perpage = 25, query, types}, thunkAPI) => {
      try {
        const response = await instance.post(ROUTES.PENDING_LIST, {
          page,
          perpage,
          query,
          types,
        });
        return response.data;
      } catch (err) {
        return thunkAPI.rejectWithValue(err?.response?.data);
      }
    }
  );
  
  export const BulkAction = createAsyncThunk(
    "hsnSac/BulkAction",
    async ({ approval_ids = [], approve }, thunkAPI) => {
      try {
        const response = await instance.post(ROUTES.BULK_ACTION, {
          approval_ids,
          approve,
        });
          return response.data;
      } catch (err) {
        return thunkAPI.rejectWithValue(err?.response?.data);
      }
    }
  );

  export const QueryList = createAsyncThunk(
    "hsnSac/QueryList",
    async ({page = 1, perpage = 25, status=[],query}, thunkAPI) => {
      try {
        const response = await instance.post(ROUTES.QUERY_LIST, {
          page,
          perpage,
          status,
          query
        });
        return response.data;
      } catch (err) {
        return thunkAPI.rejectWithValue(err?.response?.data);
      }
    }
  );

  export const getApprovalLevels = createAsyncThunk(
    "hsnSac/getApprovalLevels",
    async (thunkAPI) => {
      try {
        const response = await instance.get(ROUTES.APPROVAL_LEVEL_LIST);
        return response.data;
      } catch (err) {
        return thunkAPI.rejectWithValue(err?.response?.data);
      }
    }
  );

  export const approvalLevelUpdate = createAsyncThunk(
    "hsnSac/ApprovalLevelUpdate",
    async ({ approver_levels=[] }, thunkAPI) => {
      try {
        const response = await instance.post(ROUTES.APPROVAL_LEVEL_UPDATE, {
          approver_levels
        });
          return response.data;
      } catch (err) {
        return thunkAPI.rejectWithValue(err?.response?.data);
      }
    }
  );

  export const getQueryDetails = createAsyncThunk(
    'hsnSac/getQueryDetails',
    async ({ clientId,id }, thunkAPI) => {
      try {
        const response = await instance.get(`${ROUTES.QUERY_ADMIN_VIEW}${clientId}/${id}`);
        return response.data; 
      } catch (err) {
        return thunkAPI.rejectWithValue(err?.response?.data); 
      }
    }
  );

  export const createAdminComment = createAsyncThunk(
    'hsnSac/createAdminComment',
    async ({ clientId,id,comment }, thunkAPI) => {
      console.log("createAdminComment")
      try {
        const response = await instance.post(`${ROUTES.CREATE_ADMIN_COMMENT}${clientId}/${id}`,{
          comment
        });
        return response.data; 
      } catch (err) {
        return thunkAPI.rejectWithValue(err?.response?.data); 
      }
    }
  );

  export const getCommentList = createAsyncThunk(
    'hsnSac/getCommentList',
    async ({page = 1, perpage = 25, clientId,id }, thunkAPI) => {
      try {
        const response = await instance.post(`${ROUTES.COMMENT_LIST}${clientId}/${id}`,{
          page,
          perpage
        });
        return response.data; 
      } catch (err) {
        return thunkAPI.rejectWithValue(err?.response?.data); 
      }
    }
  );

  export const AdminQueryApprove = createAsyncThunk(
    'hsnSac/AdminQueryApprove',
    async ({clientId,id,comment_id,approve,next_level=null }, thunkAPI) => {
      try {
        const response = await instance.post(`${ROUTES.QUERY_APPROVE}${clientId}/${id}/${comment_id}`,{
          approve,
          next_level
        });
        return response.data; 
      } catch (err) {
        return thunkAPI.rejectWithValue(err?.response?.data); 
      }
    }
  );

  export const BulkUpload = createAsyncThunk(
    "hsnSac/BulkUpload",
    async ({ formData }, thunkAPI) => {
      try {
        const response = await instance.post(ROUTES.BULK_UPLOAD, formData,{
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
          return response.data;
      } catch (err) {
        return thunkAPI.rejectWithValue(err?.response?.data);
      }
    }
  );


export const hsnSacSlice = createSlice({
  name: "hsnSac",
  initialState: {
    hsnSacData: [],
    queryList: [], 
    pendingHsnSacData: [], 
    queryDetails: [], 
    bulkActionSuccess: false,
    approval_level_list:[],
    commentList:[],
    loading: false,
    error: null,
    success: false,
    successMsg: null,
    total_count: 0,
    commentId:null
    
  },
  reducers: {
    resetHsnSacState(state) {
      state.error = null;
      state.success = false;
      state.successMsg = null;
      state.total_count = 0;
      state.bulkActionSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createHsnSac.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createHsnSac.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.successMsg = "Created successfully";
       
      })
      .addCase(createHsnSac.rejected, (state, action) => {
        state.loading = false;
      
        if (action.payload && typeof action.payload === "object") {
          const errors = Object.entries(action.payload)
            .map(([key, value]) => `${key}, ${value[0]}`)
            .join(", "); 
          state.error = errors || "An error occurred.";
        } else {
          state.error = action.payload?.message || "Failed, try again";
        }
      })

      .addCase(updateHsnSac.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateHsnSac.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.successMsg = "Updated successfully";
      })
      .addCase(updateHsnSac.rejected, (state, action) => {
        state.loading = false;
      
        if (action.payload && typeof action.payload === "object") {
          const errors = Object.entries(action.payload)
            .map(([key, value]) => `${key}, ${value[0]}`)
            .join(", "); 
          state.error = errors || "An error occurred.";
        } else {
          state.error = action.payload?.message || "Failed, try again";
        }
      })


      .addCase(getHsnSac.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getHsnSac.fulfilled, (state, action) => {
        state.hsnSacData = action.payload.data?.list || [];
        state.loading = false;
        // state.success = true;
        state.total_count = action.payload.data?.total_records || 0;
      })
      .addCase(getHsnSac.rejected, (state, action) => {
        state.loading = false;
        // state.error = errorMsgCreator(action.payload);
      })

      .addCase(QueryList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(QueryList.fulfilled, (state, action) => {
        state.queryList = action.payload.data?.list || [];
        state.loading = false;
        // state.success = true;
        state.total_count = action.payload.data?.total_records || 0;
      })
      .addCase(QueryList.rejected, (state, action) => {
        state.loading = false;
        // state.error = errorMsgCreator(action.payload);
      })

      .addCase(HsnSacPendingList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(HsnSacPendingList.fulfilled, (state, action) => {
        state.pendingHsnSacData = action.payload.data?.list || [];
        state.loading = false;
        // state.success = true;
      })
      .addCase(HsnSacPendingList.rejected, (state, action) => {
        state.loading = false;
        // state.error = errorMsgCreator(action.payload.message);
      })

      .addCase(BulkAction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.bulkActionSuccess = false;
      })
      .addCase(BulkAction.fulfilled, (state) => {
        state.success = true;
        state.loading = false;
        state.bulkActionSuccess = true;
        state.successMsg = "Action completed";
      })
      .addCase(BulkAction.rejected, (state, action) => {
        state.success = false;
        state.loading = false;
        state.error = errorMsgCreator(action.payload.message || "Failed, try again");
      })

      .addCase(getApprovalLevels.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getApprovalLevels.fulfilled, (state, action) => {
        state.approval_level_list = action.payload.data?.approver_levels || [];
        state.loading = false;
        // state.success = true;
        state.total_count = action.payload.data?.total_records || 0;
      })
      .addCase(getApprovalLevels.rejected, (state, action) => {
        state.loading = false;
        // state.error = errorMsgCreator(action.payload);
      })


      .addCase(approvalLevelUpdate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(approvalLevelUpdate.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.successMsg = "Updated successfully";
      })
      .addCase(approvalLevelUpdate.rejected, (state, action) => {
        state.loading = false;
        state.error = errorMsgCreator(action.payload.message || "Failed, try again");
      })

      .addCase(getQueryDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getQueryDetails.fulfilled, (state, action) => {
        state.queryDetails = action.payload.data || [];
        state.loading = false;
        state.commentId = action.payload.data.pending_comments?.id || null;
        // state.success = true;
        // state.total_count = action.payload.data?.total_records || 0;
      })
      .addCase(getQueryDetails.rejected, (state, action) => {
        state.loading = false;
        // state.error = errorMsgCreator(action.payload);
      })

      .addCase(createAdminComment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createAdminComment.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.successMsg = "Created successfully";
       
      })
      .addCase(createAdminComment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message || "Error";
      })

      .addCase(getCommentList.pending, (state) => {
        // state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getCommentList.fulfilled, (state, action) => {
        state.commentList = action.payload.data?.list || [];
        // state.loading = false;
        // state.success = true;
        state.total_count = action.payload.data?.total_records || 0;
      })
      .addCase(getCommentList.rejected, (state, action) => {
        // state.loading = false;
        state.error = errorMsgCreator(action.payload);
      })

      .addCase(AdminQueryApprove.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AdminQueryApprove.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.successMsg = "Updated successfully";
      })
      .addCase(AdminQueryApprove.rejected, (state, action) => {
        state.loading = false;
        state.error = errorMsgCreator(action.payload.message || "Failed, try again");
      })

      .addCase(BulkUpload.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(BulkUpload.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.successMsg = action.payload.message || "Processing";
       
      })
      .addCase(BulkUpload.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message  || "Failed, try again";
      })
  },
});

export const { resetHsnSacState } = hsnSacSlice.actions;
export default hsnSacSlice.reducer;