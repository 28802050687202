import { Fragment, useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, NavLink } from "react-router-dom"
import { Modal, Button, Breadcrumb } from "react-bootstrap"

import Icon from "./common/Icon"
import folderImage from '../images/folder.png'
import folderPlusImage from '../images/folderplus.png'
import CreateNewClient from "./clients/CreateNewClient"
import { 
    addClients,
    getClients,
    resetStore,
    deleteClient,
    getSingleClient,
    updateClient
} from "../slices/clientReducer"
import {CREATE_CLIENT_SCHEMA, CREATE_CLIENT_FIELDS} from '../constants/clients';
import Loader from "./common/Loader";
import Base from "./Base"
import Alert from 'react-bootstrap/Alert';
import PaginationComponent from "./common/Pagination"
import ViewSelector from "./common/GridIconGenerator"
import ListView from "./common/ListView"
import { TABLE_HEADERS } from "../constants/clients"
import { PAGE_PERMISSIONS, CRUD_PERMISSIONS } from "../constants/user"
import useCheckAuthorization from "../hooks/checkAuthorization"


export default function Statement3(){

    const [errors, setErrors] = useState({})
    const [show, openModal] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)
    const [updateData, setValues] = useState({})
    const [showDeletModal, openDeleteModal] = useState(false)
    const [delete_id, setDeleteId] = useState(null)
    const [current_page, setCurrentPage] = useState(1);
    const { 
        data,
        loading,
        error,
        success,
        successMsg,
        total_count,
        client
     } = useSelector((state) => state.clients)
    const {gridView} = useSelector(state => state.common)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const hasPermission = useCheckAuthorization(PAGE_PERMISSIONS.client)
    const editPermission = useCheckAuthorization(CRUD_PERMISSIONS.client.edit)
    const deletePermission = useCheckAuthorization(CRUD_PERMISSIONS.client.delete)
    const viewPeriodPermission = useCheckAuthorization(PAGE_PERMISSIONS.period)
    const addPermission  = useCheckAuthorization(CRUD_PERMISSIONS.client.add)


    useEffect(()=> {
        if (!hasPermission) {
            navigate('/authorization-error');
        }
        dispatch(getClients(current_page));
        return () => dispatch(resetStore())
    }, [])

    useEffect(()=>{
        if (client.id) {
            setIsUpdate(true) 
            setValues(client)
            openModal(true)
        }
    },[client])

    const isCheckboxList = (element) => {
        return (
            element instanceof NodeList &&
            element.length > 0 &&
            element[0] instanceof HTMLInputElement &&
            element[0].type === 'checkbox'
        );
    };

    const onSubmit = async (e, isUpdate) => {
        e.preventDefault();
        const values = {};
        Object.keys(CREATE_CLIENT_FIELDS).forEach(field => {
            if (e.target[field].type === 'checkbox') {
              // For checkboxes, use the `checked` property
              values[field] = e.target[field].checked;
            } else if(isCheckboxList(e.target[field])) {
                values[field] = Array.from(e.target[field]).filter(checkbox => checkbox.checked).map(checkbox => checkbox.value);
            } else {
              values[field] = e.target[field].value;
            }
        })
        try {
             const data = await CREATE_CLIENT_SCHEMA.validate(values, { abortEarly: false });
             if (data) {
                if (!isUpdate) {
                    dispatch(addClients({data, page_number: current_page}));
                   
                }
                if (isUpdate) {
                    dispatch(updateClient({data, page_number: current_page, client_id: updateData['id']}));
                    setIsUpdate(false)
                    setValues({})
                }

                setErrors({})
                openModal(false );
             }
          } catch (err) {
            const errData =[];
            err.inner.forEach(e => {
                errData[e.path] = e.message
                setErrors(errData);
            });
          }

        
    }

    const handleOpen =() => {
        openModal(true);
    }

    const handleModalClose = () => {
        openModal(false );
    }

    const handleFolder = (id) => {
        navigate('/periods/'+id);
    }

    const handleDelete = (id) => {
        setDeleteId(id)
        openDeleteModal(true)
    }

    const handleEdit = (id) => {
        dispatch(getSingleClient(id))
    }

    const handleDeleteModalConfirm = () => {
        dispatch(deleteClient({data: delete_id, page_number: current_page}))
        setDeleteId(null)
        openDeleteModal(false)
    }

    const handleDeleteModalClose = () => {
        setDeleteId(null)
        openDeleteModal(false)
    }

    const handlePagination = (number) => {
        setCurrentPage(number)
        dispatch(getClients(number));
    }

    return (<Base sticky_footer={false} header_title={'Clients'} hideDatepicker={true} showSearchIcon={true}>
                <div className="row mb-2">
                    <div>
                      <div className="row">
                      <div className="col-md-4 col-sm-4 col-lg-4 mr-auto text-center">
                            <Breadcrumb>
                                <Breadcrumb.Item linkAs={NavLink} linkProps={{to: '/'}}>Dashboard</Breadcrumb.Item>
                                <Breadcrumb.Item active>Statement3</Breadcrumb.Item>
                            </Breadcrumb>
                            </div>      
                            <div className="col-md-2 col-sm-4 col-lg-2 ml-auto text-center ">
                                    <ViewSelector/>
                            </div>
                    </div>
                    </div>
                </div>  
                 {(success || error ) && (
                  <div className="row ">
                    <div className="col-md-12 justify-content-center d-flex">  
                        <Alert 
                            dismissible
                            variant={success ? 'success' : 'danger'}
                            onClose={() => dispatch(resetStore())}
                        >
                            <p>
                            {success ? successMsg : error}
                            </p>
                        </Alert>
                    </div>
                  </div>)}
                <div className="row">  
                    <div className="icon" style={{display: gridView == 'grid' ? 'block' : 'none'}}>
                        <div className="row">
                            {addPermission &&<Icon title="Add New Client &raquo;" imageUrl={folderPlusImage} onClickFunc={handleOpen} /> }
                            {data.map(client => {
                            return  (<Icon key={client.id} title={client.client_name} imageUrl={folderImage} onClickFunc={viewPeriodPermission ? () => handleFolder(client.id): ""} isActiveFlag={client.is_active}/>)
                            })}
                        </div>
                    </div>
                    <div className="icon"  style={{display: gridView == 'list' ? 'block' : 'none'}}>
                        <ListView
                         headers={TABLE_HEADERS}
                         data={Array.isArray(data) ? data: []}
                         handleViewFunc={(id) => handleFolder(id)}
                         handleDeleteFunc={(id) => handleDelete(id)}
                         handleEditFunc={(id) => handleEdit(id)}
                         showView={viewPeriodPermission}
                         showEdit={editPermission}
                         showDelete={deletePermission}
                       />
                    </div>
                </div>  
                <div className="row mt-10">
                    <div className="col-md-12 d-flex justify-content-center">
                    <PaginationComponent total_count={total_count} current_page={current_page} handleOnClick={handlePagination}/>
                    </div>
                </div>
                <Modal show={showDeletModal}>
                    <Modal.Header>Delete Client</Modal.Header>
                    <Modal.Body>
                    <p>Are you sure you want to delete client ?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={handleDeleteModalConfirm}>Delete</Button>
                        <Button onClick={handleDeleteModalClose}>Close</Button>
                    </Modal.Footer>
                </Modal>
                <CreateNewClient
                 fields={CREATE_CLIENT_FIELDS}
                 handleSubmit={onSubmit}
                 errors={errors}
                 show={show}
                 onHideFunc={handleModalClose}
                 isUpdate={isUpdate}
                 values={updateData}
                />
                {loading && (<Loader/>)}
                
            </Base>)
}
