import { useEffect,useState } from "react";
import Modal from "react-bootstrap/Modal";

function AddLitigation(props) {
  const {
    handleSubmit,
    fields,
    errors,
    show,
    onHideFunc,
    values,
    isUpdate ,
    id
  } = props;

  const [data, setData] = useState({});

  const handleChange = (e, field) => {
    const fieldData = { ...data };
    if (isUpdate && values.id) {
        fieldData.lit_id = values.id; 
      }
    fieldData[field] = e.target.value; 
    setData(fieldData);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault(); 
    if (handleSubmit) {
        handleSubmit(e, isUpdate, id); // Call the parent-provided submit handler with the data
      }
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      show={show}
      onHide={onHideFunc}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {!isUpdate ? "Create New Litigation" : "Update Litigation"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="grid-example">
        <div style={{ minHeight: "200px" }}>
          <div className="sign-user_card">
            <form
              className="login-content"
              onSubmit={handleFormSubmit} 
            >
              <div className="row">
                {!!fields &&
                  Object.keys(fields).map((field) => (
                    <div className="col-lg-12" key={field}>
                      <div className="floating-label form-group">
                        {fields[field]["type"] === "text" && (
                          <input
                            className="floating-input form-control"
                            name={fields[field]["name"]}
                            type={fields[field]["type"]}
                            placeholder={fields[field]["label"]}
                            value={data[field] || values[field] || ""}
                            onChange={(e) => handleChange(e, field)}
                          />
                        )}
                        {errors[field] && (
                          <span className="error text-danger">
                            {errors[field]}
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
              <button type="submit" className="btn btn-primary">
                {!isUpdate ? "Create" : "Update"} &raquo;
              </button>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AddLitigation;