import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import instance from '../services/baseService'
import { ROUTES } from '../constants/clients'
import { errorMsgCreator } from '../utils'


export const createLitigation = createAsyncThunk(
    'litigation/createLitigation',
    async ({ clientId, data }, thunkAPI) => {
      try {
        const response = await instance.post(ROUTES.CREATE_LITIGATION(clientId), data);
        if (response.data) {
          thunkAPI.dispatch(getLitigation({ clientId: clientId}))
          return response.data;
        }
      } catch (err) {
        return thunkAPI.rejectWithValue(err?.response?.data);
      }
    }
  );

export const updateLitigation = createAsyncThunk(
    'litigation/updateLitigation',
    async ({ clientId, data }, thunkAPI) => {
      try {
        const response = await instance.post(ROUTES.UPDATE_LITIGATION(clientId), data);
        if (response.data) {
          thunkAPI.dispatch(getLitigation({ clientId: clientId}))
          return response.data; 
        }
      } catch (err) {
        return thunkAPI.rejectWithValue(err?.response?.data);
      }
    }
  );

  export const getLitigation = createAsyncThunk(
    'litigation/getLitigation',
    async ({ clientId, page = 1, perpage = 25 }, thunkAPI) => {
      try {
        const response = await instance.post(`/api/v1/client/litigation/list/${clientId}`, {
          page,
          perpage, 
        });
        return response.data; 
      } catch (err) {
        return thunkAPI.rejectWithValue(err?.response?.data); 
      }
    }
  );

  export const deleteLitigation = createAsyncThunk('clients/delete', async ({clientId, data}, thunkAPI) => {
    try {
      const response = await instance.post(`/api/v1/client/litigation/delete/${clientId}`,data)
      if (response.data) {
        thunkAPI.dispatch(getLitigation({ clientId: clientId}))
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data)
    }
  })

  
export const litigationSlice = createSlice({
    name: 'litigation',
    initialState: {
      litigationData: [], 
      loading: false, 
      error: null, 
      success: false, 
      successMsg: null, 
      total_count: 0,
    },
    reducers: {
      resetLitigationState(state) {
        state.error = null;
        state.success = false;
        state.successMsg = null;
        state.total_count = 0;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(createLitigation.pending, (state) => {
        state.loading = true;
        state.error = null;
      });
      builder.addCase(createLitigation.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.successMsg = 'Successfully created';
        state.litigationData = action.payload;
      });
      builder.addCase(createLitigation.rejected, (state, action) => {
        state.loading = false;
        state.error = errorMsgCreator('Failed to create litigation', action.payload);
      });
  
      builder.addCase(updateLitigation.pending, (state) => {
        state.loading = true;
        state.error = null;
      });
      builder.addCase(updateLitigation.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.successMsg = 'Successfully updated';
        state.litigationData = action.payload;
      });
      builder.addCase(updateLitigation.rejected, (state, action) => {
        state.loading = false;
        state.error = errorMsgCreator('Failed to update litigation', action.payload);
      });

      builder
      .addCase(getLitigation.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
        
      })
      .addCase(getLitigation.fulfilled, (state, action) => {
        state.litigationData = action.payload.data?.list; 
        state.total_count = action.payload.data.total_count;
        state.loading = false;
        state.success = true;
        
      })
      .addCase(getLitigation.rejected, (state, action) => {
        state.loading = false;
        state.error = errorMsgCreator(action.payload); 
      });

      builder.addCase(deleteLitigation.pending, (state, action) => {
        state.loading = true
      });
      builder.addCase(deleteLitigation.fulfilled, (state, action) => {
        state.loading = false
        state.successMsg = 'Successfully deleted'
        state.success = true
      });
      builder.addCase(deleteLitigation.rejected, (state, action) => {
        state.error = action.payload;
      });
    },
  });
  
  export const { resetLitigationState } = litigationSlice.actions; 
  export default litigationSlice.reducer; 