import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getClients } from "../slices/clientReducer";
import { PAGE_PERMISSIONS } from "../constants/user";
import Icon from "./common/Icon";
import softwareImage from "../images/software.png";
import userIcon from "../images/users_icon.png";
import pdfUrl from "../images/pdf.png";
import clientIcon from "../images/clients.svg";
import Base from "./Base";
import useCheckAuthorization from "../hooks/checkAuthorization";
import {
  getFacilitation,
  resetFacilitationState,
} from "../slices/facilitationReducer";
function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getClients(1));
  }, []);

  const statement3Click = () => {
    navigate("/statement3");
  };

  const userFuncClick = () => {
    navigate("/user-management");
  };

  const clientsClick = () => {
    navigate("/clients");
  };
  const forexClick = () => {
    navigate("/forex");
  };
  const FacilitationClick = () => {
    dispatch(getFacilitation({})); 
    navigate("/Export-Import-Facilitation");
  };
  const HsnSacClick = () => {
    navigate("/hsn/sac");
  };

  return (
    <Base hideDatepicker={true}>
      {/* <div className="row">
                    <div className="col-lg-12">
                        <div className="d-flex align-items-center justify-content-between welcome-content mb-3">
                        <div className="navbar-breadcrumb">
                           <nav aria-label="breadcrumb">
                              <ul className="breadcrumb">
                              </ul>
                           </nav>
                        </div>
                            <div className="d-flex align-items-center">
                                <GridView/>
                                   
                            </div>    
                        </div>
                    </div>
                </div> */}
      <div className="flex gap-x-10 items-center">
        {/* {useCheckAuthorization(PAGE_PERMISSIONS.client) && (
          <Icon
            className="v-icons"
            title="Statement 3"
            imageUrl={softwareImage}
            onClickFunc={statement3Click}
          />
        )} */}
        {useCheckAuthorization(PAGE_PERMISSIONS.user) && (
          <Icon
            className="v-icons"
            title="Manage Users"
            imageUrl={userIcon}
            onClickFunc={userFuncClick}
          />
        )}
        {useCheckAuthorization(PAGE_PERMISSIONS.client) && (
          <Icon
            className="v-icons"
            title="Clients"
            imageUrl={clientIcon}
            onClickFunc={clientsClick}
          />
        )}
        <Icon
          className="v-icons"
          title="Forex Rates"
          imageUrl={clientIcon}
          onClickFunc={forexClick}
        />
        <Icon
          className="v-icons"
          title="Export Import Facilitation"
          imageUrl={clientIcon}
          onClickFunc={FacilitationClick}
        />
        <Icon
          className="v-icons"
          title="HSN/SAC"
          imageUrl={clientIcon}
          onClickFunc={HsnSacClick}
        />
      </div>
      {/* <div className="row">
        <div className="col-md-12 col-sm-12 col-lg-12">
          <br />
          <br />
          <h4>Recent Files</h4>
          <hr />
          <br />
        </div>
        <Icon
          className="v-icons"
          title="Inv-9020934892093.pdf"
          imageUrl={pdfUrl}
        />
      </div> */}
    </Base>
  );
}

export default Dashboard;
