import { FaEdit,FaCheckCircle } from "react-icons/fa";
export default function Icon(props) {
   const {
    imageUrl,
    title,
    onClickFunc,
    wrapper: Wrapper,
    wrapperProps,
    isWrapper = false,
    className,
    showEdit = false,
    onEditClick,
    autoCreated = false,
    isActiveFlag=false
} = props
   
    const handleClick = () => {
        if (typeof onClickFunc == 'function') {
            onClickFunc()
        }
    }

    return (
      <div
        className={`col-md-2 col-sm-4 col-lg-2 text-center ${
          className ? className : ""
        }`}
        onClick={handleClick}
      >
        {isWrapper ? (
          <Wrapper {...wrapperProps}>
            <img
              alt={title}
              src={imageUrl}
              className="w-50 border border-radius-1 p-2"
            />
            <h6 className="mb-2">{title}</h6>
          </Wrapper>
        ) : (
          <div className="flex flex-col justify-center  position-relative">
            <div className="position-relative ">
              <img
                alt={title}
                src={imageUrl}
                className="w-20 block mx-auto position-relative"
              />
              {isActiveFlag && (
                <FaCheckCircle
                  className="position-absolute"
                  style={{
                    top: "15px",
                    right: "55px",
                    color: "#709d3b",
                    fontSize: "20px",
                    zIndex: 2,
                  }}
                />
              )}
            </div>
            <h6 className="">{title}</h6>
          </div>
        )}
        {/* Edit Icon */}
        {showEdit && !autoCreated && (
          <div
            className="position-absolute"
            style={{
              top: "5px",
              right: "5px",
              cursor: "pointer",
              marginRight: "60px",
              zIndex: 2,
            }}
            onClick={(e) => {
              e.stopPropagation(); // Prevent triggering parent click
              if (typeof onEditClick === "function") onEditClick();
            }}
          >
            <FaEdit size={16} style={{ color: " #0d4473" }} />
          </div>
        )}
      </div>
    );
}