import {
  Row,
  Col,
  Container,
  Card,
  CardHeader,
  Button,
  Form,
  CardBody,
  Table,
  FormCheck,
  Alert,
  CardFooter,
  Breadcrumb,
  InputGroup,
  Tabs,
  Tab,
} from "react-bootstrap";
import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import useCheckAuthorization from "../../hooks/checkAuthorization";
import PaginationComponent from "../common/Pagination";

import {
  PAGE_PERMISSIONS,
  CRUD_PERMISSIONS,
  RESTRICT_ASSIGN_ROLES,
} from "../../constants/user";
import AddFacilitation from "./AddFacilitation";
import {
  createFacilitation,
  updateFacilitation,
  getFacilitation,
  deleteFacilitation,
  resetFacilitationState,
} from "../../slices/facilitationReducer";
import Base from "../Base";
import { FACILITATION_CREATION_FIELDS } from "../../constants/facilitations";

export default function ExportImportFacilitation() {
  const [showAddFacilitationModal, setShowAddFacilitationModal] =
    useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedFacilitation, setSelectedFacilitation] = useState(null);
  const [current_page, setCurrentPage] = useState(1);
  const [errors, setErrors] = useState({});
  const { client_id } = useParams();
  const { facilitationData, total_count, loading, error, success, successMsg } =
    useSelector((state) => state.facilitation);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleAddFacilitationShow = () => setShowAddFacilitationModal(true);
  const handleAddFacilitationClose = () => setShowAddFacilitationModal(false);
  const hasPermission = useCheckAuthorization(PAGE_PERMISSIONS.user);
  const addPermission = useCheckAuthorization(CRUD_PERMISSIONS.user.add);

  const handleSubmit = async (e, isUpdate, id) => {
    e.preventDefault();
    const name = e.target.name?.value;

    if (!name) {
      setErrors({ name: "Name is required" });
      console.error("Validation Errors: Name is required");
      return;
    }

    try {
      const payload = { name };
      if (!isUpdate) {
        dispatch(createFacilitation({ data: payload }));
      } else {
        dispatch(updateFacilitation({ data: { ...payload, fes_id: id } }));
      }
      setIsUpdate(false);
      setSelectedFacilitation(null);
      setErrors({});
      handleAddFacilitationClose();
    } catch (error) {
      setErrors(error);
      console.error("Submission Error:", error);
    }
  };

  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
    dispatch(getFacilitation({ page: pageNumber, perpage: 25 }));
  };

  useEffect(() => {
    // if (!hasPermission) {
    //   navigate("/authorization-error");
    // }
    dispatch(getFacilitation({ page: current_page, perpage: 25 }));
    return () => dispatch(resetFacilitationState());
  }, [dispatch, client_id, hasPermission]);

  return (
    <Base hideDatepicker={true}>
    <Container>
      <Row>
        <Col className="mr-auto text-center">
          <Breadcrumb>
            <Breadcrumb.Item linkAs={NavLink} linkProps={{ to: "/" }}>
              Dashboard
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Facilitation</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      {/* {(success || error) && (
        <Row className="justify-content-md-center">
          <Col md={3}>
            <Alert
              dismissible
              variant={success ? "success" : "danger"}
              onClose={() => dispatch(resetFacilitationState())}
            >
              <p>{success ? successMsg : error}</p>
            </Alert>
          </Col>
        </Row>
      )} */}
      <Row>
        <Col sm={12}>
          <Card>
            <CardHeader className="d-flex">
              <Col className="text-end">

                  <Button variant="primary" onClick={handleAddFacilitationShow}>
                    <span>+</span> Add New
                  </Button>
              </Col>
              {showAddFacilitationModal && (
                <AddFacilitation
                  handleSubmit={handleSubmit}
                  fields={FACILITATION_CREATION_FIELDS}
                  errors={errors}
                  show={handleAddFacilitationShow}
                  onHideFunc={handleAddFacilitationClose}
                  values={isUpdate ? selectedFacilitation : {}}
                  isUpdate={isUpdate}
                  id={isUpdate ? selectedFacilitation.id : {}}
                />
              )}
              {/* <Col sm={6} md={6} className="text-right">
                <div
                  id="user_list_datatable_info"
                  className="dataTables_filter"
                >
                  <Form.Group className="form-group mb-0">
                    <InputGroup>
                      <Form.Control placeholder="Search" ref={searchRef} />
                      <Button variant="primary" onClick={handleSearch}><i className="ri-search-line"></i></Button>
                      <Button variant="primary">
                        <i className="ri-search-line"></i>
                      </Button>
                    </InputGroup>
                  </Form.Group>
                </div>
              </Col> */}
            </CardHeader>

            <Tabs
              defaultActiveKey="facilitationList"
              id="facilitation-management-tabs"
              className="mb-3"
            >
              <Tab eventKey="facilitationList" title="Facilitation List">
                <CardBody>
                  <Col>
                    <Table
                      responsive
                      bordered
                      striped
                      id="facilitation-list-table"
                      className="mt-4"
                    >
                      <thead>
                        <tr>
                          <th scope="col">SL.NO</th>
                          <th scope="col">Name</th>
                          <th scope="col">Created At</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {facilitationData.length ? (
                          facilitationData.map((facilitation, index) => (
                            <tr key={facilitation.id}>
                              <td>{index + 1}</td>
                              <td>{facilitation.name}</td>
                              <td>{facilitation.created_date.split("T")[0]}</td>
                              <td>
                                <Button
                                  variant="link"
                                  title="Edit Facilitation"
                                  onClick={() => {
                                    setSelectedFacilitation(facilitation);
                                    setIsUpdate(true);
                                    handleAddFacilitationShow();
                                  }}
                                >
                                  <i className="ri-pencil-fill"></i>
                                </Button>
                                {/* <Button
                                    variant="link"
                                    title="Delete Facilitation"
                                    onClick={() =>
                                      handleDeleteFacilitation(facilitation.id)
                                    }
                                  >
                                    <i className="ri-delete-bin-fill"></i>
                                  </Button> */}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6" className="text-center">
                              No Facilitation Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    <PaginationComponent
                      total_count={total_count}
                      current_page={current_page}
                      handleOnClick={handlePagination}
                      per_page={25}
                    />
                  </Col>
                </CardBody>
              </Tab>
            </Tabs>
          </Card>
        </Col>
      </Row>
    </Container>
    </Base>
  );
}
