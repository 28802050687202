import { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch } from "react-redux";
import { getActiveUsers } from "../../slices/userReducer";



function CreateNewClient(props) {

    const { 
        handleSubmit,
        fields,
        errors,
        show,
        onHideFunc,
        values,
        isUpdate = false,
        editClientPermission=null
    } = props;

    const { 
        activeUsers: userData,
    } = useSelector((state) => state.user)
    const dispatch = useDispatch();

    const [data, setData] = useState({})
    useEffect(() => {
        setData(values)
        if(show)
            dispatch(getActiveUsers({}))
    },[values, show, dispatch])

    const handleChange = (e, field) => {
        const fieldData = {...data};
        if (e.target.type === 'checkbox') {
            fieldData[field] = e.target.checked;
        } else {
            fieldData[field] = e.target.value;
        }
        setData(fieldData)
    }

    const handleCheckboxGroupChange = (e, field, value) => {
        const updatedValues = data[field] ? [...data[field]] : [];
        
        if (e.target.checked) {
            updatedValues.push({ id: value, name: fields[field]['options'].find(opt => opt.value === value).label });
        } else {
            const index = updatedValues.findIndex(item => item.id === value);
            if (index > -1) {
                updatedValues.splice(index, 1);
            }
        }
        setData({ ...data, [field]: updatedValues });
    };

    return (
        <Modal aria-labelledby="contained-modal-title-vcenter" show={show} onHide={onHideFunc} backdrop="static">
        <Modal.Header closeButton >
            <Modal.Title id="contained-modal-title-vcenter">
            {!isUpdate ? 'Create New Client': 'Update Client' }
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="grid-example">
            <div style={{minHeight: "400px"}} >
                <div className="sign-user_card">   
                     <form className="login-content" onSubmit={(e) => handleSubmit(e, isUpdate)}>
                        <div className="row">
                         { !!fields && (
                            Object.keys(fields).map((field) => {
                                return (<div className="col-lg-12" key={field}>
                                    <div className={`${fields[field]['type'] !== 'checkbox' ? 'floating-label' : ''} form-group`}>
                                        {fields[field]['type'] === 'text' && (
                                            <input 
                                                className="floating-input form-control"
                                                name={fields[field]['name']}
                                                type={fields[field]['type']}
                                                placeholder=" "
                                                value={data[field] ? data[field] : ''}
                                                onChange={(e) => handleChange(e, field)}
                                            />
                                        )}
                                        {fields[field]['type'] === 'select' && (
                                            <select
                                                className="floating-input form-control"
                                                name={fields[field]['name']}
                                                value={data[field] ? data[field] : ''}
                                                onChange={(e) => handleChange(e, field)}
                                            >
                                                <option value="">Select {fields[field]['label']}</option>
                                                {userData?.map((option) => (
                                                    <option key={option.user_id} value={option.user_id}>
                                                        {option.name}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                        {fields[field]['type'] === 'checkbox' && (
                                            <div class="mb-3 text-left pl-1">
                                                <input
                                                    className="absolute top-[7px]"
                                                    name={fields[field]['name']}
                                                    type="checkbox"
                                                    id={`${fields[field]['name']}-customCheck`}
                                                    checked={!!data[field]} // Convert undefined or false to false
                                                    onChange={(e) => handleChange(e, field)}
                                                />
                                                <label className="ml-4 text-base text-[#535f6b]" htmlFor={`${fields[field]['name']}-customCheck`} >{fields[field]['label']}</label>
                                            </div>

                                        )}
                                        {fields[field]['type'] === 'checkbox_group' && (
                                            <div className="checkbox-group left-0">
                                                <label>{fields[field]['label']}</label>
                                                {fields[field]['options'].map((option) => (
                                                    <div key={option.value} className="checkbox-option mb-2  ml-3">
                                                        <input
                                                            type="checkbox"
                                                            name={fields[field]['name']}
                                                            value={option.value}
                                                            id={`${fields[field]['name']}-${option.value}`}
                                                            checked={data[field]?.some(item => item.id === option.value)}
                                                            onChange={(e) => handleCheckboxGroupChange(e, field, option.value)}
                                                        />
                                                        <label className="ml-2" htmlFor={`${fields[field]['name']}-${option.value}`}>
                                                            {option.label}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        {(fields[field]['type'] !== 'checkbox' && fields[field]['type'] !== 'checkbox_group')&& ( <label>{fields[field]['label']}</label>)}
                                        {errors[field] && (<span class="error text-danger">{errors[field]}</span>)}
                                   
                                    </div>
                                 </div>)
                            })
                         ) }
                           
                        </div>
                        <button type="submit" className="btn btn-primary" disabled={editClientPermission === false}>{!isUpdate ? 'Create': 'Update' } &raquo;</button>
                     </form>
                  </div>
                 </div> 
        </Modal.Body>
        </Modal>    
    )
}

export default CreateNewClient;