import React from "react";
import "./MultiStepProgressBar.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { FaCheck, FaClock } from "react-icons/fa";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const MultiStepProgressBar = ({ approverLevels }) => {
  const getStatus = (progress) => {
    console.log("sortedLevels",sortedLevels)
    return progress === 1 ? "approved" : "pending";
  };


  const getIcon = (status) => {
    return status === "approved" ? <FaCheck /> : <FaClock />;
  };

  const sortedLevels = Object.entries(approverLevels).sort(([a], [b]) => b - a);

  const totalSteps = sortedLevels.length;
  const completedSteps = sortedLevels.filter(
    ([, role]) => role.progress === 1
  ).length;
  const stepPercentage = completedSteps === 1 ? 50 : 100;

  if (totalSteps === 1) {
    const [roleKey, role] = sortedLevels[0];
    const status = getStatus(role.progress);

    return (
      <div className="single-step-container">
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={`tooltip-${roleKey}`}>
              {status === "approved" ? "Approved" : "Pending"}
            </Tooltip>
          }
        >
          <div
            className={`indexedStep ${
              status === "approved" ? "completed" : ""
            }`}
          >
            {getIcon(status)}
            <div
              className="mt-14"
              style={{
                fontSize: "12px",
                color: "#007bff",
                opacity: "70%",
              }}
            >
              {role.name}
            </div>
          </div>
        </OverlayTrigger>
      </div>
    );
  }

  return (
    <ProgressBar percent={stepPercentage}>
      {sortedLevels.map(([roleKey, role], index) => {
        const status = getStatus(role.progress);
        return (
          <Step key={roleKey}>
            {({ accomplished }) => (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-${roleKey}`}>
                    {status === "approved" ? "Approved" : "Pending"}
                  </Tooltip>
                }
              >
                <div
                  className={`indexedStep ${
                    status === "approved" ? "completed" : ""
                  }`}
                >
                  {getIcon(status)}
                  <div
                    className="mt-14"
                    style={{
                      fontSize: "12px",
                      color: "#007bff",
                      opacity: "70%",
                    }}
                  >
                    {role.name}
                  </div>
                </div>
              </OverlayTrigger>
            )}
          </Step>
        );
      })}
    </ProgressBar>
  );
};

export default MultiStepProgressBar;