import React, { useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const RequestPopupModal = ({ show, onHide }) => {
  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [dateType, setDateType] = useState("custom");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [error, setError] = useState("");

  const handleDateTypeChange = (e) => {
    setDateType(e.target.value);
    setStartDate(null);
    setEndDate(null);
  };

  const handleStartDateChange = (date) => {
    // Validate on start date change
    if (endDate && date > endDate) {
      setError("Start date must be earlier than the end date.");
    } else {
      setError("");
      setStartDate(date);
    }
  };

  const handleEndDateChange = (date) => {
    
    // Validate on end date change
    if (startDate && date < startDate) {
      setError("End date must be later than the start date.");
    } else {
      setError("");
      setEndDate(date);
    }
  };
  const reportTypes = [
    { id: 1, name: "E-Invoice Report" },
    { id: 2, name: "GSTR1 Detailed and Summary Report" },
    { id: 3, name: "GSTR 3 B Summary Report" },
    { id: 4, name: "GSTR2B Detailed and Summary Report" },
    { id: 5, name: "GSTR2A Detailed Report" },
    { id: 6, name: "IMS Report" },
    { id: 7, name: "ITC comparison Statement" },
    { id: 8, name: "Electronic Credit Ledger" },
    { id: 9, name: "Electronic Cash Ledger" },
    { id: 10, name: "Electronic Liability Ledger" },
  ];
  const renderDatePickers = () => {
    if (dateType === "monthly") {
      return (
        <Row>
          <Col>
            <Form.Label>Start Month</Form.Label>
            <DatePicker
              selected={startDate}
              onChange={handleStartDateChange}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              className="form-control"
              popperPlacement="top-start"
            />
          </Col>
          <Col>
            <Form.Label>End Month</Form.Label>
            <DatePicker
              selected={endDate}
              onChange={handleEndDateChange}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              className="form-control"
              popperPlacement="top-start"
            />
          </Col>
          {error && (
            <div className="text-danger mt-2" style={{ fontSize: "0.9rem" }}>
              {error}
            </div>
          )}
        </Row>
      );
    } else if (dateType === "financial") {
      return (
        <Row>
          <Col>
            <Form.Label>Start Financial Year</Form.Label>
            <DatePicker
              selected={startDate}
              onChange={handleStartDateChange}
              dateFormat="yyyy"
              showYearPicker
              className="form-control"
            />
          </Col>
          <Col>
            <Form.Label>End Financial Year</Form.Label>
            <DatePicker
              selected={endDate}
              onChange={handleEndDateChange}
              dateFormat="yyyy"
              showYearPicker
              className="form-control"
            />
          </Col>
          {error && (
            <div className="text-danger mt-2" style={{ fontSize: "0.9rem" }}>
              {error}
            </div>
          )}
        </Row>
      );
    } else {
      return (
        <Row>
          <Col>
            <Form.Label>Start Date</Form.Label>
            <DatePicker
              selected={startDate}
              onChange={handleStartDateChange}
              className="form-control"
            />
          </Col>
          <Col>
            <Form.Label>End Date</Form.Label>
            <DatePicker
              selected={endDate}
              onChange={handleEndDateChange}
              className="form-control"
            />
          </Col>
          {error && (
            <div className="text-danger mt-2" style={{ fontSize: "0.9rem" }}>
              {error}
            </div>
          )}
        </Row>
      );
    }
  };

  const handleSubmit = () => {
    console.log("Form Data:", { title, type, dateType, startDate, endDate });
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Request Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="title">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter title"
            />
          </Form.Group>

          <Form.Group controlId="type" className="mt-3">
            <Form.Label>Type</Form.Label>
            <Form.Select value={type} onChange={(e) => setType(e.target.value)}>
              <option value="">Select Type</option>
              {reportTypes.map((report) => (
                <option key={report.id} value={report.id}>
                  {report.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group controlId="dateType" className="mt-4">
            <Form.Label>Date Selection</Form.Label>
            <div>
              <Form.Check
                inline
                label="Monthly"
                type="radio"
                value="monthly"
                name="dateType"
                checked={dateType === "monthly"}
                onChange={handleDateTypeChange}
              />
              <Form.Check
                inline
                label="Financial"
                type="radio"
                value="financial"
                name="dateType"
                checked={dateType === "financial"}
                onChange={handleDateTypeChange}
              />
              <Form.Check
                inline
                label="Custom"
                type="radio"
                value="custom"
                name="dateType"
                checked={dateType === "custom"}
                onChange={handleDateTypeChange}
              />
            </div>
          </Form.Group>

          <div className="mt-3">{renderDatePickers()}</div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RequestPopupModal;
