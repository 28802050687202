import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { PAGE_PERMISSIONS, CRUD_PERMISSIONS } from "../constants/user";
import Icon from "./common/Icon";
import gstr1Icon from "../images/gstr1.svg";
import litigation from "../images/litigation.svg";
import RefundsIcon from "../images/refunds.svg";
import softwareImage from "../images/software.png";
import infoIcon from '../images/info_icon.png'
import Base from "./Base";
import useCheckAuthorization from "../hooks/checkAuthorization";
import ChatPopup from "./common/ChatPopup";
import CreateNewClient from "./clients/CreateNewClient";
import {
  getPeriods,
  resetStore,
} from "../slices/periodReducer"

import { updateClient, resetStore as clientResetStore } from "../slices/clientReducer"
import {CREATE_CLIENT_SCHEMA, CREATE_CLIENT_FIELDS} from '../constants/clients';
import { useEffect, useState } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import {
  getLitigation,
  resetLitigationState,
} from "../slices/litigationReducer";



function TaxForms() {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [current_page, setCurrentPage] = useState(1);
  //const { permissions } = useSelector((state) => state.common);
  const { client_id } = useParams();
  const [errors, setErrors] = useState({})
  //const [show, openModal] = useState(false)
  const [showClient, openClientModal ] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  //const [updateData, setValues] = useState({});
  const { 
    error,
    client,
    success,
    successMsg,
  } = useSelector((state) => state.periods);

  const {
    error: clientError,
    success: clientSuccess,
    successMsg: clientSuccessMsg
  } = useSelector((state) => state.clients);
  const editClientPermission = useCheckAuthorization(CRUD_PERMISSIONS.client.edit);
  const hasPermission = useCheckAuthorization(PAGE_PERMISSIONS.period)
  console.log("client_id", client_id);

  const gstr1Click = () => {
    navigate(`/gstr-1/${client_id}`);
  };

  const refundsClick = () => {
    navigate(`/refunds/${client_id}`);
  };

  const statement3Click = () => {
    navigate(`/periods/${client_id}`);
  };

  const gstr3bClick = () => {
    navigate(`/gstr3b/${client_id}`);
  };

  const gstr9Click = () => {
    navigate(`/gstr9/${client_id}`);
  };

  const gstr9cClick = () => {
    navigate(`/gstr9c/${client_id}`);
  };

  const litigationClick = () => {
    navigate(`/litigation/${client_id}`);
  };

  const gstRepoClick = () => {
    navigate(`/gstrepo/${client_id}`);
  };

  // const hsnClick = () => {
  //   navigate(`/hsn/sac/${client_id}`);
  // };



  useEffect(() => {
    if (!hasPermission) {
        navigate('/authorization-error');
    }
    return () =>  {
        dispatch(resetStore('all'))
        dispatch(clientResetStore())
    }
  }, []);

  useEffect(()=> {
    dispatch(getPeriods({id:client_id,page_number: current_page}));
  }, [client_id]);

  useEffect(()=> {
    if(showClient && client_id)
    dispatch(getPeriods({id:client_id,page_number: current_page}));
  }, [client_id, showClient]);   

  /*useEffect(()=>{
    if (period.id) {
        setIsUpdate(true) 
        setValues(period)
        openModal(true)
    }
  },[period]);*/

  const isCheckboxList = (element) => {
    return (
        element instanceof NodeList &&
        element.length > 0 &&
        element[0] instanceof HTMLInputElement &&
        element[0].type === 'checkbox'
    );
  };

  const onClientSubmit = async (e, isUpdate) => {
    e.preventDefault();
    const values = {};
    Object.keys(CREATE_CLIENT_FIELDS).forEach(field => {
      if (e.target[field].type === 'checkbox') {
        // For checkboxes, use the `checked` property
        values[field] = e.target[field].checked;
      } else if(isCheckboxList(e.target[field])) {
        values[field] = Array.from(e.target[field]).filter(checkbox => checkbox.checked).map(checkbox => checkbox.value);
      } else {
        values[field] = e.target[field].value;
      }
    })
    try {
         const data = await CREATE_CLIENT_SCHEMA.validate(values, { abortEarly: false });
            if (data) {
                dispatch(updateClient({data, page_number: 1, client_id: client.id}));
            }
            openClientModal(false);
            setErrors({})
            setIsUpdate(false)
         
      } catch (err) {
        const errData =[];
        err.inner.forEach(e => {
            errData[e.path] = e.message
            setErrors(errData);
        });
      }    
  }

  const handleClientModalClose = () => {
    openClientModal(false );
    setIsUpdate(false)
    dispatch(resetStore())
  }

  return (
    <Base hideDatepicker={true}>
      {(clientSuccess || clientError) && (
        <Row>
          <Col md={12} className="justify-content-center d-flex">
            <Alert
              dismissible
              transition
              variant={success || clientSuccess ? "success" : "danger"}
              onClose={() => dispatch(resetStore())}
            >
              {success ? successMsg : error}
              {clientSuccess ? clientSuccessMsg : clientError}
            </Alert>
          </Col>
        </Row>
      )}
      <div className="flex flex-wrap gap-x-12 items-center">
        {useCheckAuthorization(PAGE_PERMISSIONS.client) && (
          <>
            <Icon
              title={client && client.client_name}
              imageUrl={infoIcon}
              onClickFunc={() => {
                openClientModal(true);
                setIsUpdate(true);
              }}
            />
            <Icon
              className="v-icons"
              title="GSTR 3B"
              imageUrl={RefundsIcon}
              onClickFunc={gstr3bClick}
            />
            <Icon
              className="v-icons"
              title="GSTR 9"
              imageUrl={RefundsIcon}
              onClickFunc={gstr9Click}
            />
            <Icon
              className="v-icons"
              title="GSTR 9C"
              imageUrl={RefundsIcon}
              onClickFunc={gstr9cClick}
            />
            <Icon
              className="v-icons"
              title="GSTR 1"
              imageUrl={gstr1Icon}
              onClickFunc={gstr1Click}
            />
            <Icon
              className="v-icons"
              title="Refunds"
              imageUrl={RefundsIcon}
              onClickFunc={refundsClick}
            />
            <Icon
              className="v-icons"
              title="Litigation"
              imageUrl={litigation}
              onClickFunc={litigationClick}
            />
            <Icon
              className="v-icons"
              title="GST Repository"
              imageUrl={litigation}
              onClickFunc={gstRepoClick}
            />

            {/* <Icon
            className="v-icons"
            title="HSN/SAC"
            imageUrl={litigation}
            onClickFunc={hsnClick}
          /> */}
          </>
        )}
        {useCheckAuthorization(PAGE_PERMISSIONS.client) && (
          <Icon
            className="v-icons"
            title="Statement 3"
            imageUrl={softwareImage}
            onClickFunc={statement3Click}
          />
        )}
      </div>
      <ChatPopup client_id={client_id} chat_type="GENERAL" />
      <CreateNewClient
        fields={CREATE_CLIENT_FIELDS}
        handleSubmit={onClientSubmit}
        errors={errors}
        show={showClient}
        onHideFunc={handleClientModalClose}
        isUpdate={isUpdate}
        values={client || {}}
        editClientPermission={editClientPermission}
      />
    </Base>
  );
}

export default TaxForms;
