import React, { useState,useEffect} from "react";
import { useDispatch } from "react-redux";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import { BulkUpload } from "../../slices/hsnSacReducer";

const BulkImportModal = ({ show, onHide, type }) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    setError("");
    setFile(null);
  }, [show]);


  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      const fileType = uploadedFile.name.split(".").pop().toLowerCase();
      if (fileType !== "xlsx") {
        setError("Only .xlsx files are allowed!");
        setFile(null);
      } else {
        setError("");
        setFile(uploadedFile);
      }
    }
  };

  const handleSubmit = () => {
    if (!file) {
      setError("Please upload a valid .csv file.");
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    formData.append("types", type);
    
    dispatch(BulkUpload({ formData }))
      .unwrap()
      .then(() => {
        onHide(); 
        setFile(null); 
      })
      .catch(() => {
        setError("Failed to upload file. Please try again.");
      });
  };

  return (
    <Modal show={show} onHide={onHide} centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Bulk Import</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form>
          <Form.Group>
            <Form.Label>Upload CSV File</Form.Label>
            <Form.Control
              type="file"
              accept=".xlsx"
              onChange={handleFileChange}
            />
            <Form.Text className="text-muted">
              <a
                href="/assets/files/HSN&SACDownloadSample.xlsx"
                download
                style={{ textDecoration: "underline" }}
              >
                Download Sample File
              </a>
            </Form.Text>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={!file} 
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BulkImportModal;